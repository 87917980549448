
import { isString, isEmpty } from "lodash";
import crypto from "crypto";
import { secretHalf } from "./constants";
import { secretHalfLast } from "./constantsSecret";

const EMAIL_REGEXP = new RegExp(
  /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
);
export const removeSensitiveData = (str: string) => {
  // 邮箱：若无则显示“-”，信息展示需要脱敏处理（邮箱 @前> 3位，则 @前的最后3位显示3个*，其余完整展示。@后完整显示。若 @前小于等于3位，则全部显示，并在 @前加3个*，@后完整显示）
  // 手机号：若无则显示“-”，信息展示需要脱敏处理（仅保留后四位数字）
  //脱敏函数
  if (isString(str) && !isEmpty(str)) {
    //国外手机号不一定11位
    // if (/^1[3-9]\d{9}$/.test(str)) {
    //   return str.replace(/\d*(\d{4})/, '*******$1');
    // } else
    if (EMAIL_REGEXP.test(str)) {
      // return str.replace(/(.{0,3}).*@(.*)/, '$1***@$2');
      const result = str.split("@");
      const name = result[0];
      const host = result[1];
      return (
        name.slice(0, name.length > 3 ? name.length - 3 : 3) + "***@" + host
      );
    } else {
      const temp = str.split("");
      const arr: any = [];
      temp.map((item, index) => {
        if (index >= temp.length - 4) {
          arr.push(item);
        } else {
          arr.push("*");
        }
      });
      return arr.join("");
    }
  }
  return "-";
};

const secretKey = secretHalf + secretHalfLast; // 密钥，需要保密
const iv = crypto.randomBytes(16); // 初始化向量，需要保密

export const encrypt = (text) => {
  const cipher = crypto.createCipheriv("aes-256-cbc", secretKey, iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return `${iv.toString("hex")}:${encrypted.toString("hex")}`;
};

export const decrypt = (text) => {
  const [ivHex, encryptedHex] = text.split(":");
  const iv = Buffer.from(ivHex, "hex");
  const encrypted = Buffer.from(encryptedHex, "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", secretKey, iv);
  let decrypted = decipher.update(encrypted);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};
