<template>
  <section class="create-account">
    <!-- 标题 -->
    <h1 class="title">{{ langCreateAccount.createTitle }}</h1>
    <!-- <p class="desc">{{ langCreateAccount.verifiTip }}</p> -->
    <area-port :portInfor="portInfor" />
    <!-- 输入框 -->
    <input-normal class="input-t" @inpObj="(val) => {
      emailInpObj = val;
    }
      " :placeholder="langCreateAccount.langCreateAccount_account_input_holder" />
    <!-- 获取验证码 -->
    <el-button v-dbClick type="primary" @click="getCode" :class="['get-code-btn', disabledFlag ? 'el-disabled' : '']"
      round>
      {{ langCreateAccount.verifiBtn }}
    </el-button>
    <!-- 相关协议 -->
    <agree-policy ref="agreePolicy" @checkFn="checkFn" />
    <!-- 返回登录 -->
    <!-- <div class="back-btn" @click="backFn">{{ langCreateAccount.backTip }}</div> -->
  </section>
</template>

<script>
import { mapState } from "vuex";
// import { langCreateAccount } from "./language";
import AgreePolicy from "./components/agree-policy.vue";
import areaPort from "./components/area-port.vue";
import InputNormal from "./components/input-normal.vue";
import { alexaVerify, alexaSendCode } from "@/api/alexaLogin.js";
export default {
  name: "createAccount",
  data() {
    return {
      emailInpObj: {
        // email 输入框
        inpValue: "",
        inputType: "text",
        placeholder: "Enter email",
        autoFocus: true,
      },
      portInforNor: {
        //默认值
        area: "美国",
        phoneCode: 1,
        countryCode: "US",
      },
      disabledFlag: true, // 按钮是否禁用
    };
  },
  computed: {
    ...mapState("alexaLogin", [
      "tabIndex",
      "portIndex",
      "portList",
      "languagePackage",
    ]),
    langCreateAccount() {
      return this.languagePackage[this.tabIndex];
    },
    portInfor() {
      return this.portList.length > 0
        ? this.portList[this.portIndex]
        : this.portInforNor;
    },
  },
  components: { AgreePolicy, InputNormal, areaPort },
  beforeRouteEnter(to, from, next) {
    // 判断上一个页面路径是否为 login
    next((vm) => {
      vm.$keepFlag = from.name === "alexaLogin";
    });
  },
  mounted() {
    console.log("this.portIndex", this.portIndex);
  },
  activated() {
    // 如果是登录页面跳转过来，就重置当前data数据，避免被 keep-alive 缓存
    if (this.$keepFlag) {
      Object.assign(this.$data, this.$options.data());
      this.$refs.agreePolicy.agreePrivacy = false;
    }
  },
  methods: {
    async getCode() {
      if (this.disabledFlag)
        return this.$toast(this.langCreateAccount.langCreateAccount_agreeToast);
      // 判断邮箱格式是否正确
      console.log("this.emailInpObj----注册-------->", this.emailInpObj);
      let account = this.emailInpObj.inpValue.trim();
      let flag = Boolean;
      if (account.indexOf("@") != "-1") {
        flag = this.$commonFunc.verifyEmail(account);
      } else {
        flag = this.$commonFunc.verifyPhoneNo(account);
      }
      // const flag =
      //   this.$commonFunc.verifyEmail(account) ||
      //   this.$commonFunc.verifyPhoneNo(account);
      if (!flag)
        return this.$toast(this.langCreateAccount.langCreateAccount_emailToast);
      let userFlag = 0; //0：邮箱、1：手机，
      this.portInfor.mobile = "";
      if (account.indexOf("@") == "-1") {
        userFlag = 1;
        this.portInfor.mobile = account;
        account = `${this.portInfor.phoneCode}${account}`;
      }
      // 判断账号是否注册
      alexaVerify({ account })
        .then(() => {
          // 获取验证码，跳转页面
          return alexaSendCode({
            verifyIdReceiver: account,
            type: 1,
            userFlag,
            language: this.$commonFunc.getApiLanguage(this.tabIndex),
          })

        }).then(res => {
          console.log('alexaSendCode', res);
          // `/${this.$commonFunc.getUrlChannelAppID()}/verification-code?from=register&account=${account}
          this.$router.push(
            `/${this.$commonFunc.getUrlChannelAppID()}/verification-code${this.$commonFunc.linkUrlParams(
              { initParams: { from: "register", account: account } }
            )}`
          );
        })
        .catch((err) => {

          console.log(err);
          if (err.status !== 200) {
            this.$toast(err.statusText);
          } else {
            if (err?.data?.code === 14003) {
              this.$toast(this.langCreateAccount.registerToast);
            } else {
              this.$toast(err.data.msg);
            }
          }

        });
    },
    backFn() {
      this.$router.back(-1);
    },
    checkFn(val) {
      this.disabledFlag = !val;
    },
  },
};
</script>

<style lang="less" scoped>
.create-account {
  padding: 40px 30px 0 30px;
  font-size: 14px;

  .title {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
  }

  .desc {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  .input-t {
    // margin-top: 20px;
    height: 48px;
  }

  .get-code-btn {
    margin-top: 80px;
    width: 100%;
    height: 44px;
    border: none;
  }

  .el-disabled {
    background-color: #a0cfff;
  }

  .back-btn {
    font-size: 16px;
    color: #267aff;
    margin-top: 54px;
    text-align: center;
  }
}
</style>
