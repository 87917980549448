import axios from "axios";
// import QS from "qs"; // 引入qs模块，用来序列化post类型的数据，
import { ElMessage, ElMessageBox } from "element-plus";
import { getCookie, encrypSHA256 } from "@/utils/CookieUtils";
import router from "../router";
import commonFunc from "../utils/commonFunc";
import KEYS from "./keys";
import { APP_CONFIG } from "../utils/appConstant";
const BASE_URL = process.env.VUE_APP_API_URL;
let msgBool = true; //解决一个页面弹框多次的问题
// 接口错误码不弹出ElMessage的列表
const NO_MSG_ERROR_CODE = [
  1101, //产品上线失败
  14004, // 用户存在，密码错误
  14012, // 用户不存在
  1104, //上传固件版本号过低
  1107, //上传设备ID文件错误
];
// 创建axios实例
// console.log("BASE_URL", BASE_URL);
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 30000, // 请求超时时间
});

// 添加request拦截器
http.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      appId: APP_CONFIG[commonFunc.getUrlAppId()].appId || "1020",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 添加response拦截器
http.interceptors.response.use(
  (response) => {
    if (response.data.code !== 0) {
      if (
        !NO_MSG_ERROR_CODE.includes(response.data.code) &&
        !response.config.noMsgFlag
      ) {
        // ElMessage.error("网络异常,请稍后再试");
      }
      if (+response.data.code === 14005) {
        //14005
        if (msgBool) {
          msgBool = false;
          ElMessageBox.alert(response.data.msg, "提示", {
            confirmButtonText: "确定",
            callback: () => {
              msgBool = true;
              // router.push({ path: '/login' })
              router.replace({
                name: "Login",
                query: { redirect: router.currentRoute.fullPath }, //登录后再跳回此页面时要做的配置
              });
            },
          });
        }
      }
      return Promise.reject(response);
    }
    return Promise.resolve(response.data.data);
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log(401);
          break;
        case 403:
          console.log(403);
          break;
        case 404:
          console.log(404);
          break;
        case 500:
          console.log(500);
          break;
      }
    }
    console.log(!error.response.config.noMsgFlag);
    if (!error.response.config.noMsgFlag) ElMessage.error("请求失败!");
    return Promise.reject(error.response);
  }
);

function get(url, params = {}) {
  return http({
    url,
    method: "GET",
    headers: {
      language: "zh_CN",
      reqId: Date.now(),
      accessToken: getCookie("accessToken"),
    },
    params,
  });
}

const PRO_KEY = KEYS

// 封装post请求
// 生产环境 签名的秘钥：PROD_VnoClJI9aikS8dyy
function post(url, data = {}, noMsgFlag) {
  //每个接口都得国家码 除了获取国家码接口
  const countryCode = getCookie("countryCode");
  if (url !== "v1/user/area/get") {
    data.countryCode = countryCode;
  }

  // console.log(process.env, data, url);
  //加密body(HmacSHA256)
  let sign = "";
  let random = "";
  let dataS = "";
  let OEM_KEY = "";
  if (Object.keys(data).length !== 0) {
    dataS = JSON.stringify(data);
  } else {
    data = "";
  }
  if (
    window.location.hostname === "dev-us.dollin.net" ||
    window.location.hostname === "sit-us.dollin.net" ||
    window.location.hostname === "uat-us.dollin.net" ||
    window.location.hostname === "www-dev.iotdeviceconnect.com" ||
    window.location.hostname === "www-sit.iotdeviceconnect.com" ||
    window.location.hostname === "www-uat.iotdeviceconnect.com" ||
    process.env.NODE_ENV == "development"
  ) {
    OEM_KEY = "SIT_4VjZdg19laDoIrut";
  } else {
    //1104 PA的key   PROD_MIGfMA0GCSqGSIb
    // if (commonFunc.getUrlAppId() === "1104") {
    //   OEM_KEY = "PROD_MIGfMA0GCSqGSIb";
    // } else {
    //   //1020 公版的key
    //   OEM_KEY = "PROD_VnoClJI9aikS8dyy";
    // }
    OEM_KEY = PRO_KEY[APP_CONFIG[commonFunc.getUrlAppId()].appId || 1020];
  }
  const OEM_SECRET = "meicloud";
  random = Math.floor(Math.random() * 1000000000000 + 1);
  const str = OEM_SECRET + dataS + random;
  sign = encrypSHA256(str, OEM_KEY);
  // console.log("OEM_KEY", OEM_KEY);
  //加密body(HmacSHA256)
  return http({
    url,
    method: "POST",
    headers: {
      language: data.language,
      random,
      sign,
      "Content-Type": "application/json",
      accessToken: getCookie("accessToken"),
      reqId: Date.now(),
    },
    data,
    noMsgFlag,
  });
}

function upload(url, data = {}, onUploadProgress) {
  let h = {
    "Content-Type": "multipart/form-data",
    language: "zh_CN",
    accessToken: getCookie("accessToken"),
    reqId: Date.now(),
  };
  return http({
    url,
    method: "POST",
    headers: h,
    data,
    onUploadProgress,
  });
}

export default {
  get,
  post,
  upload,
};
