<template>
  <section class="set-password">
    <!-- 标题 -->
    <h1 class="title">{{ langResetPws.langResetPws_title }}</h1>
    <!-- <p class="tips">{{ langResetPws.langResetPws_desc }}</p> -->
    <area-port :portInfor="portInfor" />
    <!-- 邮箱 -->
    <input-normal @inpObj="(val) => { inpObj.inpValue = val.inpValue }"
      :placeholder="langResetPws.langResetPws_account_input_holder" />
    <!-- 按钮 -->
    <el-button @click="completeFn" class="el-btn" :class="{ 'el-disabled': inpValLenFlag }" type="primary" round>
      {{ langResetPws.btnText }}
    </el-button>
  </section>
</template>

<script>
import { mapState } from "vuex";
// import { langResetPws } from "./language";
import areaPort from "./components/area-port.vue";
import InputNormal from "./components/input-normal.vue";
import { alexaVerify, alexaSendCode } from "@/api/alexaLogin.js";
export default {
  name: "resetPassword",
  data() {
    return {
      inpObj: {
        inpValue: "",
        placeholder: "Enter email",
        autoFocus: true,
      },
      portInforNor: {
        //默认值
        area: "美国",
        phoneCode: 1,
        countryCode: "US",
      },
    };
  },
  computed: {
    ...mapState("alexaLogin", [
      "tabIndex",
      "portList",
      "portIndex",
      "languagePackage",
    ]),
    langResetPws() {
      return this.languagePackage[this.tabIndex];
    },
    inpValLenFlag() {
      return this.inpObj.inpValue.length === 0;
    },
    portInfor() {
      return this.portList.length > 0
        ? this.portList[this.portIndex]
        : this.portInforNor;
    },
  },
  components: { InputNormal, areaPort },
  beforeRouteEnter(to, from, next) {
    // 判断上一个页面路径是否为 login
    next((vm) => {
      vm.$keepFlag = from.name === "alexaLogin";
    });
  },
  activated() {
    // 如果是登录页面跳转过来，就重置当前data数据，避免被 keep-alive 缓存
    if (this.$keepFlag) {
      Object.assign(this.$data, this.$options.data());
    }
  },
  methods: {
    completeFn() {
      if (this.inpValLenFlag) return;
      // 校验邮箱
      // console.log('this.inpObj-----修改密码------->',this.inpObj)
      let account = this.inpObj.inpValue.trim();
      // const accountFlag =
      //   this.$commonFunc.verifyEmail(account) ||
      //   this.$commonFunc.verifyPhoneNo(account);
      let accountFlag = Boolean;
      if (account.indexOf("@") != "-1") {
        accountFlag = this.$commonFunc.verifyEmail(account);
      } else {
        accountFlag = this.$commonFunc.verifyPhoneNo(account);
      }
      if (!accountFlag)
        return this.$toast(this.langResetPws.langResetPws_emailToast);
      // const userFlag = account.indexOf("@") != '-1'? 0 : 1//0：邮箱、1：手机，
      let userFlag = 0; //0：邮箱、1：手机，
      if (account.indexOf("@") == "-1") {
        userFlag = 1;
        account = `${this.portInfor.phoneCode}${account}`;
      }
      // 判断账号是否已注册
      alexaVerify({ account })
        .then(() => {

          this.$toast(this.langResetPws.accountToast);
        })
        .catch((err) => {
          // 忘记密码，已注册，跳转校验码页面
          const flag = err?.data?.code === 14003;
          alexaSendCode({
            verifyIdReceiver: account,
            type: 2,
            userFlag,
            language: this.$commonFunc.getApiLanguage(this.tabIndex),
          }).then(res => {
            console.log('alexaSendCode->', res);
            if (flag) {
              this.$router.push(
                `/${this.$commonFunc.getUrlChannelAppID()}/verification-code${this.$commonFunc.linkUrlParams(
                  { initParams: { from: "reset", account: account } }
                )}`
              );
            } else {
              this.$toast(err.data.msg);
            }
          }).catch(err => {
            console.log('err->', err);
            this.$toast(err.data.msg);
          })

        });
    },
  },
};
</script>

<style lang="less" scoped>
.set-password {
  font-size: 14px;
  padding: 0 30px;

  .title {
    padding-top: 40px;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
  }

  .tips {
    margin: 12px 0 20px 0;
    color: rgba(0, 0, 0, 0.4);
  }

  .el-btn {
    width: 100%;
    margin-top: 80px;
    height: 44px;
    border: none;

    &.el-disabled {
      background-color: #a0cfff;
    }
  }
}
</style>
