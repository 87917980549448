import sha256 from "crypto-js/sha256";
const CryptoJS = require("crypto-js");
import { computed } from "vue";
import { useStore, mapState, mapMutations } from "vuex";
import { getCookie } from "@/utils/CookieUtils";
import { LANGS_ARR } from "../views/login/alexa/language/index";
import { APP_CONFIG } from "./appConstant";
import {
  secretHalf,
  secretHalf4031,
  secretHalf4032,
  secretHalf8011,
  secretHalf8012,
} from "./constants";
import {
  secretHalfLast,
  secretHalfLast4031,
  secretHalfLast4032,
  secretHalfLast8011,
  secretHalfLast8012,
} from "./constantsSecret";
import { decrypt, encrypt, removeSensitiveData } from "./functions";

const splitUrl = (url) => {
  //通过寻找url中的第一个h5
  const index = url.indexOf("h5");
  return [url.substring(0, index), url.substring(index + 2)];
};

export default {
  // 校验手机号码
  removeSensitiveData,
  encrypt,
  decrypt,
  verifyPhoneNo(phoneNo) {
    // const regexp =
    //   /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0-3,5-8]|18[0-9]|19[89])\d{8}$/;
    // return regexp.test(phoneNo);
    const digitRule = getCookie("digitRule");
    console.log('digitRule ->' + digitRule);

    return phoneNo.length === +digitRule;
  },
  // 校验邮箱
  verifyEmail(email) {
    const regexp =
      // /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/; 该正则校验邮箱后面加50位数字会导致卡死，替换正则
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regexp.test(email);
  },
  // 校验密码
  verifyPassword(password) {
    const regexp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/;
    return regexp.test(password);
  },
  // 加密验证码
  encryptCode(password) {
    const keyIv = sha256("f526b03e0b591a6eeddf4fe77b9517fa").toString();
    const key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    const iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  encryptCodeSecret(password, secret) {
    const keyIv = sha256(secret).toString();
    const key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    const iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  // 加密密码
  encryptPassword(password, appId) {
    if (APP_CONFIG[appId]?.microClean) {
      //微清的加密方法
      return this.encryptWQPassword(password, appId);
    }
    const pwd256 = sha256(password).toString();
    let keyIv = sha256(secretHalf + secretHalfLast).toString();
    if (APP_CONFIG[appId].appId === 8011) {
      keyIv = sha256(secretHalf8011 + secretHalfLast8011).toString();
    } else if (APP_CONFIG[appId].appId === 8012) {
      keyIv = sha256(secretHalf8012 + secretHalfLast8012).toString();
    }
    const key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    const iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    const encrypted = CryptoJS.AES.encrypt(pwd256, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  //微清加密
  encryptWQPassword(password, appId) {
    let key256 = sha256(secretHalf + secretHalfLast).toString();
    if (APP_CONFIG[appId].appId === 4032) {
      key256 = sha256(secretHalf4032 + secretHalfLast4032).toString();
    } else if (APP_CONFIG[appId].appId === 4031) {
      key256 = sha256(secretHalf4031 + secretHalfLast4031).toString();
    }
    // const realKey = key256.subString(0, 16);
    // const realIv = key256.subString(16, 16);
    const realKey = CryptoJS.enc.Utf8.parse(key256.substring(0, 16));
    const realIv = CryptoJS.enc.Utf8.parse(key256.substring(16, 32));
    // const realKey = key256.toString(CryptoJS.enc.Utf8).substring(0, 16);
    // const realIv = key256.toString(CryptoJS.enc.Utf8).substring(16, 32);
    debugger;
    const encrypted = CryptoJS.AES.encrypt(password, realKey, {
      iv: realIv,
      padding: CryptoJS.pad.Pkcs7,
    });
    debugger;
    return encrypted.ciphertext.toString();
  },

  // 加密密码
  encryptNewOEMPassword(password, secret) {
    const pwd256 = sha256(password).toString();
    const keyIv = sha256(secret).toString();
    const key = CryptoJS.enc.Utf8.parse(keyIv.substring(0, 16));
    const iv = CryptoJS.enc.Utf8.parse(keyIv.substring(16, 32));
    const encrypted = CryptoJS.AES.encrypt(pwd256, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },
  // 旧OEM加密密码
  encryptOldOEMPassword(password) {
    const pwd256 = sha256(password).toString();
    return pwd256;
  },
  // 获取当前网站链接解码参数
  getUrlDecodeURIParams() {
    const url = location.search; //获取url中"?"符后的字串
    const theRequest = new Object();
    if (url.indexOf("?") != -1) {
      const str = url.substring(1);
      const strArr = str.split("&");
      for (let i = 0; i < strArr.length; i++) {
        theRequest[strArr[i].split("=")[0]] = decodeURIComponent(
          strArr[i].split("=")[1]
        );
      }
    }
    return theRequest;
  },
  // 获取当前网站链接参数
  getUrlParams() {
    const url = location.search; //获取url中"?"符后的字串
    const theRequest = new Object();
    if (url.indexOf("?") != -1) {
      const str = url.substring(1);
      const strArr = str.split("&");
      for (let i = 0; i < strArr.length; i++) {
        theRequest[strArr[i].split("=")[0]] = strArr[i].split("=")[1];
      }
    }
    return theRequest;
  },
  //获取当前url后缀为Google或者Alexa
  getUrlChannel() {
    const isOr = window.document.URL.indexOf("?");
    let url = window.document.URL;
    if (isOr) {
      url = url.split("?")[0];
    }
    const urlArr = splitUrl(url);
    const hashArr = urlArr[1].split("/");
    //添加了appId
    console.log("HASH", hashArr);
    return `${hashArr[1]}`;
  },
  getUrlChannelAppID() {
    const isOr = window.document.URL.indexOf("?");
    let url = window.document.URL;
    if (isOr) {
      url = url.split("?")[0];
    }
    // const newHash = hash.indexOf("google") != -1 ? "google" : "alexa";
    let newUrl = "";
    // if (hash.indexOf("google") != -1) {
    //   newHash = "google";
    // } else if (hash.indexOf("alexa") != -1) {
    //   newHash = "alexa";
    // } else if (hash.indexOf("yandex") != -1) {
    //   newHash = "yandex";
    // } else if (hash.indexOf("ifttt") != -1) {
    //   newHash = "ifttt";
    // }
    const urlArr = splitUrl(url);
    const hashArr = urlArr[1].split("/");
    //添加了appId
    newUrl = `${hashArr[1]}/${hashArr[2]}`;
    console.log("HASH", newUrl);
    return newUrl;
  },
  //获取appId
  getUrlAppId() {
    const isOr = window.document.URL.indexOf("?");
    let url = window.document.URL;
    if (isOr) {
      url = url.split("?")[0];
    }
    const urlArr = splitUrl(url);
    const hashArr = urlArr[1].split("/");
    //添加了appId
    const appId = `${hashArr[2]}`;
    return appId;
  },
  //获取语种
  getApiLanguage(tabIndex) {
    const index = this.getUrlAppId();
    return (LANGS_ARR[index || 1020] || LANGS_ARR[1020])[tabIndex || 0];
  },
  //setup函数的computed中使用mapStatelang
  useMapper(mondules) {
    const store = useStore();
    const func = computed(() => ({ ...mapState([mondules]) })).value[
      mondules
    ].bind({ $store: store });
    return func();
  },
  //link路由跳转
  linkRouter(query) {
    const { state, authorizationCode, redirect_uri } = query;
    // window.location.href = `${redirect_uri}?state=${state}&code=${authorizationCode}`;
    let url = "";
    if (redirect_uri.indexOf("?code") != -1) {
      // old版本-url = `${redirect_uri}=${authorizationCode}&state=${state}`;
      url = `${redirect_uri}=${authorizationCode}${this.linkUrlParams({
        initParams: { state },
        connector: "&",
      })}`;
    } else {
      // old版本- url = `${redirect_uri}?code=${authorizationCode}&state=${state}`;
      url = `${redirect_uri}${this.linkUrlParams({
        initParams: { code: authorizationCode, state },
      })}`;
    }
    window.location.href = url;
  },
  //自动读取当前url的携带参数并返回
  linkUrlParams({ initParams = {}, connector = "?" } = {}) {
    let params = this.getUrlParams();
    params = {
      ...params, //url上已有
      ...initParams, //需要新添加的字段
    };
    if (Object.keys(initParams).length === 0) {
      // 判断携带参数直接跟链接
      const isOr = window.document.URL.indexOf("?");
      const url = window.document.URL;
      if (isOr > 0) {
        return "?" + url.split("?")[1];
      }
      return "";
    } else {
      const arr = Object.keys(params);
      let str = connector || "?";
      arr.map((item, index) => {
        str +=
          item + "=" + params[item] + (index === arr.length - 1 ? "" : "&");
      });
      return str;
    }
  },
};
