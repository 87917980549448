// 脚本自动生成
export const APP_CONFIG = {
  "1020": {
    "newOEM": true,
    "microClean": false,
    "appId": 1020,
    "appName": "HolaBrain",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1020/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1020/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1101": {
    "newOEM": true,
    "microClean": false,
    "appId": 1101,
    "appName": "Aircon+",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1101/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1101/soft_license_en_US.html",
    "langs": {
      "zh_HK": "zh_HK",
      "en_US": "en_US"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1102": {
    "newOEM": true,
    "microClean": false,
    "appId": 1102,
    "appName": "O2 Smart",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1102/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1102/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "ar_SA": "ar_SA"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1104": {
    "newOEM": true,
    "microClean": false,
    "appId": 1104,
    "appName": "Perfect Home Comfort",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1104/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1104/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1105": {
    "newOEM": true,
    "microClean": false,
    "appId": 1105,
    "appName": "Mando Smart",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1105/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1105/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1124": {
    "newOEM": true,
    "microClean": false,
    "appId": 1124,
    "appName": "K-Deluxe",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1124/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1124/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1125": {
    "newOEM": true,
    "microClean": false,
    "appId": 1125,
    "appName": "GTRONIC",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1125/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1125/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1126": {
    "newOEM": true,
    "microClean": false,
    "appId": 1126,
    "appName": "OMNImax",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1126/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1126/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "1301": {
    "newOEM": true,
    "microClean": true,
    "appId": 1301,
    "appName": "eureka",
    "appLogo": false,
    "region": true,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1301&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1301&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "1310": {
    "newOEM": true,
    "microClean": true,
    "appId": 1310,
    "appName": "ALTECH Robot",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=f913c7089d72492bb33b8eaff5b64e40&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=f913c7089d72492bb33b8eaff5b64e40&type=agreement&lang=en",
    "langs": {
      "es": "es",
      "en_US": "en"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "4020": {
    "newOEM": true,
    "microClean": true,
    "appId": 4020,
    "appName": "씽크에어 PRO",
    "appLogo": false,
    "region": true,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4020&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4020&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "4031": {
    "newOEM": true,
    "microClean": true,
    "appId": 4031,
    "appName": "James Robot",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4031&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4031&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "4032": {
    "newOEM": true,
    "microClean": true,
    "appId": 4032,
    "appName": "H-smart",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4032&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4032&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "7031": {
    "newOEM": true,
    "microClean": false,
    "appId": 7031,
    "appName": "goMSolar",
    "appLogo": false,
    "region": true,
    "policyLink": "http://globalrac.midea.com/projects/ene_aftersale/appH5/privacyPolicy.html",
    "licenseLink": "http://globalrac.midea.com/static/hepims/installer/agreement.html",
    "langs": {},
    "policyType": 0,
    "licenseType": 0
  },
  "8010": {
    "newOEM": true,
    "microClean": false,
    "appId": 8010,
    "appName": "iLetComfort",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.midea.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "8011": {
    "newOEM": true,
    "microClean": false,
    "appId": 8011,
    "appName": "MYOMNIA Smart",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.mideaibp.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "8012": {
    "newOEM": true,
    "microClean": false,
    "appId": 8012,
    "appName": "MYIDOLA Smart",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.mideaibp.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "943d63546f2d4087a9a496653cc55f03": {
    "newOEM": false,
    "microClean": false,
    "appId": 1005,
    "appName": "Ariston Clima",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "e5269e30d27c47a996506542479ba2bb": {
    "newOEM": false,
    "microClean": false,
    "appId": 1091,
    "appName": "Toshiba AC",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "b575b60a5b774d358ded88a93c3e9f79": {
    "newOEM": false,
    "microClean": false,
    "appId": 1092,
    "appName": "IRIS",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "8c3e8eca9e7d4af3a1752766d194c778": {
    "newOEM": false,
    "microClean": false,
    "appId": 1017,
    "appName": "Mirage",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "5e376d636d444979a9ccbb855e7e50bf": {
    "newOEM": false,
    "microClean": false,
    "appId": 1098,
    "appName": "TECO",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "4d626f005a374777b8fb3e270cd18613": {
    "newOEM": false,
    "microClean": false,
    "appId": 1102,
    "appName": "MRCOOL Remote",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "e616c6f04728400787cd57c632193736": {
    "newOEM": false,
    "microClean": false,
    "appId": 1103,
    "appName": "PerfectAire",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "806e8f1a79bf46159d256721c4fe84c7": {
    "newOEM": false,
    "microClean": false,
    "appId": 1105,
    "appName": "Surrey Smart",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "1e7f990ace7941ff9c46eff1f89889b9": {
    "newOEM": false,
    "microClean": false,
    "appId": 1106,
    "appName": "Carrier air conditioner",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "561a550580284981832d27f7aafad0c7": {
    "newOEM": false,
    "microClean": false,
    "appId": 1107,
    "appName": "Inventor",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "8c4ca1d6b3d4481dac6ea7b0e09c2131": {
    "newOEM": false,
    "microClean": false,
    "appId": 1108,
    "appName": "NetHome Plus",
    "appLogo": false,
    "region": false,
    "policyLink": "https://smartapp-eu1.oss-accelerate.aliyuncs.com/static/customerAgreements/PrivacyAgreement_1108_nhp_en.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "7fbe4cbced9d49e1acae42422d90d07e": {
    "newOEM": false,
    "microClean": false,
    "appId": 1109,
    "appName": "Trane",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "33007b1b754a431881e2058f339d7124": {
    "newOEM": false,
    "microClean": false,
    "appId": 1111,
    "appName": "Payne Panel",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "7985c82d14d241c883682bd4f26db7d3": {
    "newOEM": false,
    "microClean": false,
    "appId": 1112,
    "appName": "Bryant ControlBox",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "07c1ec9d55bc4e4694057aea62be8f92": {
    "newOEM": false,
    "microClean": false,
    "appId": 1113,
    "appName": "Carrier CliMate",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "43efc1af45604a6ab47cbedf307fcf7f": {
    "newOEM": true,
    "microClean": true,
    "appId": 1301,
    "appName": "eureka",
    "appLogo": false,
    "region": true,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1301&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1301&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "4ba128c7ca6649189b267c37c26cc4d7": {
    "newOEM": false,
    "microClean": false,
    "appId": 1114,
    "appName": "Olimpia",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "3a5a10ed8e214516b1a0237aaf5b2b55": {
    "newOEM": false,
    "microClean": false,
    "appId": 1302,
    "appName": "Zamil",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "770ec4c0d8ea4a54a819b57a33e8fc7e": {
    "newOEM": false,
    "microClean": false,
    "appId": 1303,
    "appName": "York",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "785364ffd60e4ff6ab0e1df431dedc14": {
    "newOEM": false,
    "microClean": false,
    "appId": 1018,
    "appName": "Arctic king",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "02978ee4227b4423a0930c62aad8b38c": {
    "newOEM": false,
    "microClean": false,
    "appId": 1115,
    "appName": "Senville",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "fa8a0c6e87c94674af608a161d81e22e": {
    "newOEM": false,
    "microClean": false,
    "appId": 1116,
    "appName": "Comfort Star",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "df7f4169b9474e4fb6c4a576e799c4b9": {
    "newOEM": false,
    "microClean": false,
    "appId": 1304,
    "appName": "POWERbot-E",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1304&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1304&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "5c1a9e77579d4a428dddedffa7de6b12": {
    "newOEM": false,
    "microClean": false,
    "appId": 1117,
    "appName": "Midea Air",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "8b4125a894c64b8587efe4257806e7ed": {
    "newOEM": false,
    "microClean": false,
    "appId": 1305,
    "appName": "Weissgauff",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1305&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1305&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "57be8306157c4957a83a5c8f5be53092": {
    "newOEM": false,
    "microClean": false,
    "appId": 1118,
    "appName": "Actron Air",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "633acd57b7c3463db3e4cd5bd7470fc4": {
    "newOEM": false,
    "microClean": false,
    "appId": 1306,
    "appName": "Fakir",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1306&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1306&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "19513d0de35f426a91251d7265a21415": {
    "newOEM": false,
    "microClean": false,
    "appId": 1119,
    "appName": "Danby",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "ff7a96cc6afa4e66b5481093bf27206a": {
    "newOEM": false,
    "microClean": false,
    "appId": 1308,
    "appName": "SamyClean",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1308&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=1308&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "436f1ecd57ac4b448b2d61a63d34f9ae": {
    "newOEM": false,
    "microClean": false,
    "appId": 4002,
    "appName": "Ferroli",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "b7a2d473df3e4cd3ae466cba27d886fa": {
    "newOEM": false,
    "microClean": false,
    "appId": 4003,
    "appName": "Lamborghini",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "47f9eae24f5e420287dfd09b014d5e67": {
    "newOEM": false,
    "microClean": false,
    "appId": 2039,
    "appName": "Comfort Home",
    "appLogo": false,
    "region": false,
    "policyLink": "https://hbtapp-prod.midea.com/privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.midea.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "en",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "en",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "2d7066aac6704c1ca743752255141650": {
    "newOEM": false,
    "microClean": false,
    "appId": 2040,
    "appName": "SENCOR",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2040&type=agreement&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2040&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "3139ff24ff9a4deb9266a3d6f70634b8": {
    "newOEM": false,
    "microClean": false,
    "appId": 2042,
    "appName": "EVVO",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2042&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2042&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "2d143c024b8a4009bb6f1490fa3eedae": {
    "newOEM": false,
    "microClean": false,
    "appId": 2044,
    "appName": "VEAVON",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2044&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2044&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "f2c593a9301d4b859d33554e8e46e49b": {
    "newOEM": false,
    "microClean": false,
    "appId": 4004,
    "appName": "타마 로봇청소기",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4004&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4004&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "1eb47552c2114983a58700d668435e06": {
    "newOEM": false,
    "microClean": false,
    "appId": 2139,
    "appName": "HAAM AC",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "9d59b25090f845e5a1aa99f4de06402d": {
    "newOEM": false,
    "microClean": false,
    "appId": 4005,
    "appName": "OMNIA Smart",
    "appLogo": false,
    "region": false,
    "policyLink": "https://hbtapp-prod.midea.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.midea.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "en",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "en",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "6c54734b1c2143ce8390eeec55b16149": {
    "newOEM": false,
    "microClean": false,
    "appId": 4006,
    "appName": "IDOLA Smart",
    "appLogo": false,
    "region": false,
    "policyLink": "https://hbtapp-prod.midea.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.midea.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "en",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "en",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "492d1774d0e845c1b7e31b9d3101424f": {
    "newOEM": false,
    "microClean": false,
    "appId": 1121,
    "appName": "C&H",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "61a178ccf8484fcd9fd51226b6114b28": {
    "newOEM": false,
    "microClean": false,
    "appId": 2046,
    "appName": "Ultracomb",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2046&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2046&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "cb706134c9cd428cab336af761a25542": {
    "newOEM": false,
    "microClean": false,
    "appId": 2049,
    "appName": "Klauben",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2049&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=2049&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "3f698e3e5ad24d9cae7c25631c05dfd8": {
    "newOEM": false,
    "microClean": false,
    "appId": 1122,
    "appName": "Pohl Schmitt",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "91171bf95eea4f5dbc1319857da99157": {
    "newOEM": false,
    "microClean": false,
    "appId": 4013,
    "appName": "Khone",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "3d9ea330a2b1452aac415a23a2446110": {
    "newOEM": true,
    "microClean": true,
    "appId": 4020,
    "appName": "씽크에어 PRO",
    "appLogo": false,
    "region": true,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4020&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4020&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "618bdc29e8b246c48166d1856e10ed6d": {
    "newOEM": false,
    "microClean": false,
    "appId": 4021,
    "appName": "Alliance",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "36b211ee4c174a299f6710c01da0bc91": {
    "newOEM": false,
    "microClean": false,
    "appId": 4022,
    "appName": "Sea Breeze+",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "aae9f8d0f7c0429784e45ce347630814": {
    "newOEM": false,
    "microClean": false,
    "appId": 1058,
    "appName": "ITALTHERM AIR CONDITIONING",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "aa56e3b84e20416197c9c104175da71a": {
    "newOEM": false,
    "microClean": false,
    "appId": 1060,
    "appName": "MERHEIN LIMITED",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "d2e6b39ac9d346a488894be342823575": {
    "newOEM": false,
    "microClean": false,
    "appId": 1062,
    "appName": "3-Star Company",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "e5662de8afa14ac18c04d7566c33e29a": {
    "newOEM": false,
    "microClean": false,
    "appId": 1132,
    "appName": "Sankey Home",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "fe8987e64a52412098337f7f6dacfab9": {
    "newOEM": false,
    "microClean": false,
    "appId": 1066,
    "appName": "Mika smart",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "b7a7a4aadcc3446585b1db989b0a75c9": {
    "newOEM": false,
    "microClean": false,
    "appId": 1072,
    "appName": "Kaden Air",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "58a28618d5b143a9bb835f72cdb59850": {
    "newOEM": false,
    "microClean": false,
    "appId": 1134,
    "appName": "MARS Home Zone",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "6ebc56bbfc714951b0d2390d20b526a0": {
    "newOEM": true,
    "microClean": false,
    "appId": 1020,
    "appName": "HolaBrain",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1020/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1020/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "b86c15ee40c54edab63b24605030de41": {
    "newOEM": true,
    "microClean": false,
    "appId": 1101,
    "appName": "Aircon+",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1101/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1101/soft_license_en_US.html",
    "langs": {
      "zh_HK": "zh_HK",
      "en_US": "en_US"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "2c9cb2ac7ba14035870dbd388ff55750": {
    "newOEM": true,
    "microClean": false,
    "appId": 1102,
    "appName": "O2 Smart",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1102/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1102/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "ar_SA": "ar_SA"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "746f99bcdda14029a3794937ee68eb7d": {
    "newOEM": true,
    "microClean": false,
    "appId": 1104,
    "appName": "Perfect Home Comfort",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1104/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1104/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "b0a6ca0b7c9749f785c1b5430342b6de": {
    "newOEM": true,
    "microClean": false,
    "appId": 1105,
    "appName": "Mando Smart",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1105/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1105/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "c494a9d749a4413e98893f1ae12ea2c1": {
    "newOEM": true,
    "microClean": false,
    "appId": 1124,
    "appName": "K-Deluxe",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1124/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1124/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "44ffbdf9fd3346e0b8f64c5ef66b95eb": {
    "newOEM": true,
    "microClean": false,
    "appId": 1125,
    "appName": "GTRONIC",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1125/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1125/soft_license_en_US.html",
    "langs": {
      "zh_CN": "zh_CN",
      "zh_HK": "zh_HK",
      "en_US": "en_US",
      "ru_RU": "ru_RU",
      "ar_SA": "ar_SA",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "15253dd6061042a8a7c550e3e08cd7a5": {
    "newOEM": true,
    "microClean": false,
    "appId": 1126,
    "appName": "OMNImax",
    "appLogo": true,
    "region": true,
    "policyLink": "https://us.dollin.net/static/protocol/1126/privacy_policy_en_US.html",
    "licenseLink": "https://us.dollin.net/static/protocol/1126/soft_license_en_US.html",
    "langs": {
      "en_US": "en_US",
      "fr": "fr"
    },
    "policyType": 2,
    "licenseType": 2
  },
  "b532b17f1c8b4ac3bc9911a6c3d3d258": {
    "newOEM": true,
    "microClean": true,
    "appId": 4031,
    "appName": "James Robot",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4031&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4031&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "c5e881e0580042cfa950870974e6ccca": {
    "newOEM": true,
    "microClean": true,
    "appId": 4032,
    "appName": "H-smart",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4032&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=4032&type=agreement&lang=en",
    "langs": {
      "zh_CN": "zh",
      "zh_HK": "zh_TW",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "ar",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "8dcd0079855746f88a8a16e25f06c995": {
    "newOEM": true,
    "microClean": false,
    "appId": 7031,
    "appName": "goMSolar",
    "appLogo": false,
    "region": true,
    "policyLink": "http://globalrac.midea.com/projects/ene_aftersale/appH5/privacyPolicy.html",
    "licenseLink": "http://globalrac.midea.com/static/hepims/installer/agreement.html",
    "langs": {},
    "policyType": 0,
    "licenseType": 0
  },
  "7148824a801c4d26ac9a1033656fe24a": {
    "newOEM": true,
    "microClean": false,
    "appId": 8010,
    "appName": "iLetComfort",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.midea.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "bee1f66c0dbd4eab94eca9e388d7209b": {
    "newOEM": false,
    "microClean": false,
    "appId": 1209,
    "appName": "Panasonic Interios",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "a7c7d62d68884574bb6a184fc058ba78": {
    "newOEM": false,
    "microClean": false,
    "appId": 1211,
    "appName": "AirBLP",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "a4cdaca9cf2e48449efb482d9b8f759b": {
    "newOEM": false,
    "microClean": false,
    "appId": 1283,
    "appName": "CLIMAsmart",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "y8g6v1d3r6t2r3j8u6l3t1p9v5e6h6r0": {
    "newOEM": true,
    "microClean": false,
    "appId": 8011,
    "appName": "MYOMNIA Smart",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.mideaibp.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "p9e9n2h8j5d1c2w1l5d7z4k3a6y8h2m3": {
    "newOEM": true,
    "microClean": false,
    "appId": 8012,
    "appName": "MYIDOLA Smart",
    "appLogo": false,
    "region": true,
    "policyLink": "https://hbtapp-prod.mideaibp.com/ferroli-lamborghini_privacy/?lang=en",
    "licenseLink": "https://hbtapp-prod.mideaibp.com/services/en.html",
    "langs": {
      "zh_CN": "ch",
      "zh_HK": "tw",
      "en_US": "en",
      "ru_RU": "ru",
      "ar_SA": "sa",
      "fr": "fr",
      "de": "de",
      "es": "es",
      "it": "it",
      "pt": "pt",
      "pl": "pl"
    },
    "policyType": 1,
    "licenseType": 3
  },
  "v2s6g9q7o2a3u2j2h7r8d5v6u0f4w7p4": {
    "newOEM": false,
    "microClean": false,
    "appId": 1235,
    "appName": "Astech AC",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "v3z2f2z2e9z0b5h5k5z3k9x7g0v3f1b0": {
    "newOEM": false,
    "microClean": false,
    "appId": 1243,
    "appName": "YONAN Home",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-2.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-2.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "o1qez3r4fugaznsrf0rop1rchtcnaefk": {
    "newOEM": true,
    "microClean": true,
    "appId": 1310,
    "appName": "ALTECH Robot",
    "appLogo": false,
    "region": false,
    "policyLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=f913c7089d72492bb33b8eaff5b64e40&type=privacy&lang=en",
    "licenseLink": "https://api-ca.midea.com/v1/app2pro/licence?appId=f913c7089d72492bb33b8eaff5b64e40&type=agreement&lang=en",
    "langs": {
      "es": "es",
      "en_US": "en"
    },
    "policyType": 1,
    "licenseType": 1
  },
  "imu1kprdw2blfsta4wc4d981zx6j9km4": {
    "newOEM": false,
    "microClean": false,
    "appId": 1244,
    "appName": "Moovair Mobile",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "f7aecbe956d547a2b5f476ca9bc47dd5": {
    "newOEM": false,
    "microClean": false,
    "appId": 7037,
    "appName": "Condor Smart Connect",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "d2991b6872cf480aac05f9cc6a0a1fda": {
    "newOEM": false,
    "microClean": false,
    "appId": 7038,
    "appName": "ACiQ Home",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "nrm1gt16fq2qztf4nm9hsr8sbyteh363": {
    "newOEM": false,
    "microClean": false,
    "appId": 7040,
    "appName": "VIVAX Cool Net",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "chiigltwwy5l47wd0q77lj54rebhgl43": {
    "newOEM": false,
    "microClean": false,
    "appId": 7041,
    "appName": "AC Pro",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "lj2xcr20lgk6ho9w9b3ykcmkfr8o1kbo": {
    "newOEM": false,
    "microClean": false,
    "appId": 7042,
    "appName": "Convectair Connect ",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  },
  "cx776a9kkygkqmx67vqyf0lbbt2d4czj": {
    "newOEM": false,
    "microClean": false,
    "appId": 7043,
    "appName": "Premium Levella Smart ",
    "appLogo": false,
    "region": false,
    "policyLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/privacy_notice.html",
    "licenseLink": "http://midea-air-us-east1.oss-us-east-1.aliyuncs.com/GDPR_License/general/english/service_agreement.html",
    "langs": {
      "en_US": "english"
    },
    "policyType": 0,
    "licenseType": 0
  }
}